<template>
    <h1>NotReceivedBox</h1>
</template>

<script>
// @ is an alias to /src

export default {
    name: 'NotReceivedBox',
    components: {
    },
    data: function() {
        return {
        }
    }
}
</script>

